export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Productos',
    route: 'products',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Carrito',
    route: 'checkout',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Mis pedidos',
    route: 'orders-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Mis puntos',
    route: 'points',
    icon: 'AwardIcon',
  },
  {
    title: 'Cerrar sesión',
    route: 'logout',
    icon: 'LogOutIcon',
  },

]
