<template>
  <div class="navbar-container d-flex content align-items-center justify-content-between">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <div
      class="gradient-points cursor-pointer"
      @click="$router.push({name: 'profile-rewards'})"
    >
      <span>{{ getUserPoints }} puntos</span> <!--todo:Poner los puntos en movil-->
    </div>

    <div class="logo-section d-xl-flex d-none">
      <img
        :src="getMainLogo"
        alt="PromoShop"
        width="165"
      >
    </div>

    <!-- Menú al centro -->
    <b-navbar-nav class="menu-section justify-content-center align-items-center d-xl-flex d-none menu-promoshop">
      <template v-for="item in navItems">
        <b-nav-item
          v-if="!item.submenu"
          :key="item.name"
          :to="{ name: item.name }"
          :class="{ 'active-route': $route.name === item.name }"
        >
          {{ item.label }}
        </b-nav-item>
        <b-nav-item-dropdown
          v-else
          :key="item.name"
          :text="item.label"
          right
          :class="{ 'active-route': isRouteInSubmenu(item) || $route.name === item.name }"
        >
          <b-dropdown-item
            v-for="subItem in item.submenu"
            :key="subItem.name"
            :to="{ name: subItem.name, params: { category: subItem.params.category } }"
          >
            {{ subItem.label }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </template>
    </b-navbar-nav>

    <b-navbar-nav class="account-section d-flex align-items-center">
      <dark-Toggler
        class="mr-1"
      />
      <b-nav-item>
        <feather-icon
          class="text-body mr-1"
          icon="SearchIcon"
          size="21"
          @click="searchProduct()"
        />
      </b-nav-item>

      <b-nav-item
        to="/checkout"
      >
        <feather-icon
          class="text-body mr-1"
          icon="ShoppingCartIcon"
          size="21"
        />
      </b-nav-item>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <cart-dropdown />
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="UserIcon"
            size="18"
          />

          <div class="d-sm-flex d-none user-nav mr-0">
            <p class="user-name font-weight-bolder mb-0">
              Mi cuenta
            </p>
          </div>

          <feather-icon
            class="text-body mr-1"
            icon="ChevronDownIcon"
            size="15"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile-information' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Mis Datos</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'all-orders' }"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Mis compras</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'checkout' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>Carrito</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>
<!--todo:revisar para que se hagan filtros del menu de mochilas todo etc-->
<script>
import {
  BLink, BNavItem, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import store from '@/store'
import { IS_CENTROAMERICA } from '@/config/app'

export default {
  components: {
    BLink,
    BNavItem,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    CartDropdown,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      navItems: [],
    }
  },
  computed: {
    getMainLogo() {
      if (this.isDark) {
        // eslint-disable-next-line global-require
        return IS_CENTROAMERICA ? require('@/assets/images/logo/redesign/PROMOSHOP LOGO latinoamerica ok blanco.png') : require('@/assets/images/logo/logo-white.svg')
      }
      // eslint-disable-next-line global-require
      return IS_CENTROAMERICA ? require('@/assets/images/logo/redesign/PROMOSHOP LOGO latinoamerica ok.png') : require('@/assets/images/logo/logo.svg')
    },
    getUserName() {
      console.log(this.$store.state.account)
      if (!this.$store.state.account.user.name) {
        return ''
      }
      return this.$store.state.account.user.name.split(' ').slice(0, 2).join(' ').trim()
        .match(/\b\w/g)
        .join('')
    },
    getUserPoints() {
      if (!this.$store.state.account.user.points) {
        return 0
      }
      return this.$store.state.account.user.points
    },
  },
  async mounted() {
    const categories = await store.dispatch('products/listCategories')
    const subMenu = [
      { name: 'products', label: 'Todo', params: { category: '' } },
    ]
    categories.data.taxonomies.forEach(category => {
      category.taxons.forEach(taxon => {
        if (category.slug === 'envio-rapido') {
          return
        }
        subMenu.push({
          name: 'products', label: taxon.name, params: { category: `${category.slug}/${taxon.slug}` },
        })
      })
    })

    this.navItems = [
      { name: 'home', label: 'Inicio', hover: false },
      {
        name: 'products',
        label: 'Todos los promocionales',
        hover: false,
        submenu: subMenu,
      },
      { name: 'new-arrivals', label: 'Últimos lanzamientos', hover: false },
      { name: 'outlet', label: 'Outlet', hover: false },
      { name: 'liquidacion', label: 'Liquidación', hover: false },
      // { name: 'envio-rapido', label: 'Envío rápido', hover: false },
    ]
  },
  methods: {
    logout() {
      this.$store.dispatch('account/logout')
    },
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
    isRouteInSubmenu(item) {
      if (item.submenu && item.submenu.length > 0) {
        return item.submenu.some(subItem => this.$route.name === subItem.name)
      }
      return false
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/variables';
.brand-logo{
  width: 140px!important;
  img{
    max-width: 200px!important;
    width: 140px!important;

  }
}
.navbar-header-l {
  left: calc(-30% - 56px);
  position: relative;
}
.menu-promoshop {
  .nav-item{
    a {
      padding: 10px 15px !important;
    }
  }
}
.dropdown-toggledropdown-toggle{
  padding: 10px 15px !important;
}
.active-route {
  font-weight: bold;
  position: relative;
  &:after{
      content: "";
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;
      height: 5px;
      background-color: $primary; /* O el color que desees para la barra */
      border-radius: 2px 2px 0px 0px;
  }
}
.gradient-points{
  background: rgb(246,162,27);
  background: -moz-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a21b",endColorstr="#4a2268",GradientType=1);
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  color: #fff;
  display: none;
}
.dropdown-item {
  padding: 0.5rem 1.28rem;
}
.floating-nav {
  z-index: 9999999999999999!important;
}
@media (max-width: 767px) {
  .gradient-points{
    display: block;
  }
}

</style>
