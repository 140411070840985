<template>
  <div>
    <div
      class="float-points btn-gradient-promo cursor-pointer"
      @click="$router.push({name: 'profile-rewards'})"
    >
      <feather-icon
        size="20"
        icon="StarIcon"
        class="mb-icon"
      />
      <span> {{ getUserPoints }} puntos</span> <!--todo:Poner los puntos en desktop-->
    </div>
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT  © {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href="https://promoshopclarios.com/terminos-y-condiciones"
          target="_blank"
        >Términos y Condiciones</b-link>,
        <span class="d-none d-sm-inline-block pr-1">
          <b-link
            class="ml-25 "
            href="https://promoshopclarios.com/aviso-privacidad"
            target="_blank"
          > Aviso de Privacidad</b-link>,
        </span>
        <span class="d-none d-sm-inline-block pr-1">
          Servicio a cliente:
          <b-link
            v-if="!isCentroAmerica"
            class="ml-25"
            href="mailto:soporte@promoshopclarios.com"
            target="_blank"
          >soporte@promoshopclarios.com</b-link> <b-link
            v-if="isCentroAmerica"
            class="ml-25"
            href="mailto:soporte@promoshopclarioscam.com"
            target="_blank"
          >soporte@promoshopclarioscam.com</b-link>,
        </span>
        <span class="d-none d-sm-inline-block pr-1">
          Teléfono:
          <b-link
            v-if="isCentroAmerica"
            class="ml-25"
            href="tel:526647060354"
            target="_blank"
          >+526647060354</b-link><b-link
            v-if="!isCentroAmerica"
            class="ml-25"
            href="tel:6647060354"
            target="_blank"
          >6647060354</b-link>,
        </span>
        <span class="d-none d-sm-inline-block pr-1">
          Chat en línea:
          <b-link
            class="ml-25"
            href="tel:6647060354"
            target="_blank"
          >Lunes a viernes de 9am a 5pm, America/Mexico_City (GMT -6)</b-link>
        </span>
      </span>
    </p>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { IS_CENTROAMERICA } from '@/config/app'

export default {
  components: {
    BLink,
  },
  computed: {
    isCentroAmerica() {
      return IS_CENTROAMERICA
    },
    getUserPoints() {
      if (!this.$store.state.account.user.points) {
        return 0
      }
      return this.$store.state.account.user.points
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
.btn-gradient-promo{
  background: rgb(246,162,27);
  background: -moz-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a21b",endColorstr="#4a2268",GradientType=1);
  border: none;
}
.float-points{
  z-index: 9!important;
  position: fixed;
  right: 0px;
  top: 100px;
  background-color: #25d366;
  color: #fff!important;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
  -webkit-box-shadow: 2px 2px 10px rgba(39,39,39,.3);
  -moz-box-shadow: 2px 2px 10px rgba(39,39,39,.3);
  box-shadow: 2px 2px 10px rgba(39,39,39,.3);
  font-size: 20px;
  padding: 10px 20px;
  .mb-icon{
    margin-bottom: 3px;
  }
}
@media (max-width: 767px) {
  .float-points{
    display: none;
  }
}</style>
